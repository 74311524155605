import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";
import CaseStudyLists from "../case-study";
import PostsList from "../posts-list";
import Subscribe from "../subscribe";
import TrackVisibility from 'react-on-screen';
import TitleBlock from "./TitleBlock";

const getComponents = (caseStudies, homepageCustomFields, customFields) => {
    return (
        (customFields === 'blog_block') ?
            <TrackVisibility once offset={100}>
                <PostsList data={caseStudies}/>
            </TrackVisibility> :
            (customFields === 'case_studies_block') ?
                <TrackVisibility once offset={700}>
                    <CaseStudyLists data={caseStudies}/>
                </TrackVisibility> :
                null
    )
};

const TitleLists = ({data}) => {
    const {
        titleH2,
        titleH5,
        description,
        button,
        link,
        customFields,
        caseStudies,
        homepageCustomFields
    } = data;

    return (
        <>
            <div className={'case-studies-title case-studies-list-wrapper visible animated'}>
                <div className={'left-container flex-container'}>
                    <div className={'title-holder'}>
                        <TrackVisibility once offset={50}>
                            <TitleBlock
                                titleH2={titleH2}
                                titleH5={titleH5}
                                link={link}
                            />
                        </TrackVisibility>
                    </div>

                </div>

                {!!description && (
                    <div className={'right-container 4'}
                         data-animated-group="animated-content">
                        {(customFields === 'case_studies_block') && (
                            <div className={'right-container'}
                                 data-animated-group="animated-content">
                                <p className={'paragraph-primary__bold body-font-styles'}>{description}</p>
                            </div>
                        )}
                    </div>
                )}

                <div>
                    {getComponents(caseStudies, homepageCustomFields, customFields)}
                    {(customFields === 'blog_block') && (
                        <Subscribe/>
                    )}
                </div>
                {!!link && !!button && (
                    <AniLink to={normalizePath(link)}
                             paintDrip
                             duration={1}
                             hex="#ed5d2b"
                             className={'transition-link paragraph-primary__bold possibilities-link mc-link main-link mc-button-secondary right'}>
                        {button}</AniLink>
                )}
            </div>
        </>
    )
}

export default TitleLists

